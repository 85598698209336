'use strict'

###*
 # @ngdoc directive
 # @name mundoSocket.directive:MundoSocketConnectionStatus

 # @description

###
angular
  .module 'mundoSocket'
  .directive 'mundoSocketConnectionStatus', [
    '$mdPanel'
    'MundoSocket'
    ($mdPanel, MundoSocket) ->
      MundoSocketConnectionStatusBase = {}
      MundoSocketConnectionStatusBase.restrict = 'E'
      MundoSocketConnectionStatusBase.templateUrl = 'mundo-socket/views/mundo-socket-connection-status.tpl.html'
      MundoSocketConnectionStatusBase.scope = {}
      MundoSocketConnectionStatusBase.link = (scope, element, attrs) ->
        scope.MundoSocket = MundoSocket
        scope.isConnected = false
        scope.showDetails = (event) ->
          position = $mdPanel.newPanelPosition()
            .relativeTo '.socket-server-status-button'
            .addPanelPosition $mdPanel.xPosition.ALIGN_END, $mdPanel.yPosition.BELOW

          config =
            attachTo: angular.element(document.body)
            templateUrl: 'mundo-socket/views/mundo-socket-connection-status.dialog.tpl.html'
            controller: [
              'mdPanelRef'
              'socket'
              '$scope'
              (mdPanelRef, socket, $scope) ->
                $scope.socket = socket
            ]
            controllerAs: 'ctrl'
            position: position
            locals:
              socket: MundoSocket
            openFrom: event
            clickOutsideToClose: true
            escapeToClose: true
            focusOnOpen: false
            zIndex: 70

          $mdPanel.open config

        scope.$watch 'MundoSocket.isConnected()', (newValue, oldValue) ->
          scope.isConnected = newValue

      MundoSocketConnectionStatusBase
  ]
